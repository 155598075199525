@import "@/styles/_variables.scss";
/* HTML Elements */
@font-face {
  font-family: 'Iconic Engine';
  src: url('/fonts/IconicEngineLight.eot');
  src: url('/fonts/IconicEngineLight.eot?#iefix') format('embedded-opentype'),
    url('/fonts/IconicEngineLight.woff2') format('woff2'),
    url('/fonts/IconicEngineLight.woff') format('woff'),
    url('/fonts/IconicEngineLight.ttf') format('truetype'),
    url('/fonts/IconicEngineLight.svg#IconicEngineLight') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;  // Ensure text remains visible during webfont load
}
.ie-logo {
  font-family: "Iconic Engine";
  text-transform: uppercase;
  margin-left: 20px;
}

html, body {
  color: var(--text-color); 
  background: var(--app-background-color); 
  text-align: center;
  overflow: auto;
}

h1, h2, h3, h4, h5 {
  color: var(--text-color);
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
}

a {
  color: var(--app-primary-color);
}

a:hover {
  color: var(--text-color);
}

hr {
  border-top: 1px solid var(--border-color);
}

.uk-hexagon {
  clip-path: path('M10 67.2L120.9 2.8C127.2-0.8 134.8-0.8 141.1 2.9L251.1 67.2C257.2 70.8 261 77.4 261 84.5L261 188.8C261 195.7 257.4 202.2 251.4 205.8L143.2 271.8C136.9 275.6 129 275.7 122.7 272L9.9 205.8C3.8 202.2 0 195.6 0 188.5L0 84.5C0 77.4 3.8 70.8 10 67.2Z');
  background: var(--app-background-color-gradient);
  height: 18em;
  transform: scale(0.2);
}

/* Text */
.uk-text-small {
  font-size: 0.75em;
  line-height: 1.25;
  color: var(--muted-text-color);
}

/* Badges */
.uk-badge-small {
  font-size: 0.4em;
  min-width: 8px;
  height: 8px;
  padding: 0;
  margin-right: 4px;
}

/* Sidebar */
#sidebar {
  min-height: 100vh;
  text-align: left;
  border-right: 1px solid var(--border-color);
  overflow-y: auto;
  .uk-nav-divider {
    border-top: 1px solid var(--border-color);
  } 

  img {
    padding-left: 40px;
  }

  a {
    text-transform: uppercase;
    padding: 15px 10px 15px 40px;
  }
  ul > li > a:hover {
    color: var(--white-color);
    background: var(--app-background-color-gradient);
  }
  .uk-active {
  
    a {
      color: var(--white-color);
    }
    background: var(--app-primary-color);
  }

  li {
    font-size: 11px;
  }
}

/* Buttons */
.uk-card-secondary.uk-card-body .uk-button-primary,
.uk-button-primary {
  font-size: 11px;
  border-radius: 32px;
  color: var(--white-color);
  background: var(--border-color-gradient);
  border: 1px solid var(--border-color);
  box-shadow: 0px 2px 4px var(--shadow-color);
  padding: 0px 40px;
}

.uk-button-primary:disabled {
  background: var(--app-background-color);
  pointer-events: none;
}

.uk-button-secondary:disabled {
  background: var(--muted-text-color);
  color: var(--text-color);
  border-color: var(--border-color);
}
.uk-button-secondary:hover {
  background: var(--muted-text-color)!important;
}

.uk-button-default {
  font-size: 11px;
  border-radius: 32px;
  color: var(--text-color);
  background: var(--app-secondary-color);
  padding: 0px 40px;
}

.uk-button-default:hover {
  color: var(--text-color)
}

.uk-button-secondary {
  font-size: 11px;
  color: var(--text-color);
  border: 1px solid var(--border-color);
  background: var(--app-shadow-color);
}

// Disabling hover effect
.uk-card-secondary.uk-card-body .uk-button-primary:hover,
.uk-button-primary:hover {
  color: var(--white-color);
  // background: var(--app-primary-color);
}
 
.uk-button-group {
  .uk-button-default {
    font-size: 0.85em;
    border-radius: 0;
    padding: 0px 8px;
    border: 1px solid var(--border-color);
    color: var(--text-color);
    background: var(--app-secondary-color);
  }
  .uk-active {
    color: var(--white-color);
    background: var(--app-primary-color);
  }
  :hover {
    color: var(--text-color);
    background: var(--app-highlight-color);
  }
}

.uk-button-small {
  font-size: 0.8em;
}

/* Tables */
.uk-table-small {
  font-size: 12px;

  thead {
    background: var(--app-background-color-gradient); 
  }
  th {
    color: var(--white-color);
    font-size: 12px;
  }
}

.uk-table-hover {
  background: var(--app-background-color);
}

.uk-table-hover tbody tr:hover {
  background: var(--app-highlight-color-gradient); 
}

.uk-table-striped tbody tr:nth-of-type(odd) {
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  background: var(--app-highlight-color-gradient); 
}

.uk-pagination > .uk-active {
  background: var(--app-highlight-color-gradient); 
}

/* Modals */
.uk-modal-dialog {
  background: var(--app-modal-color); 

  .uk-modal-footer {
    background: var(--app-modal-color);
    border-top: 1px solid var(--border-color);
  }

  .uk-button-default {
    color: var(--text-color);
    background: var(--app-shadow-color);
    // border: 1px solid var(--border-color);
  }

  .uk-modal-header {
    background: var(--app-modal-color);
    border-bottom: 1px solid var(--border-color);
  }
}

.uk-modal-danger {
  color: var(--white-color); 
  background: var(--app-danger-color); 
  box-shadow: var(--shadow-color) 0px 10px 20px, var(--shadow-color) 0px 6px 6px;
  
  .uk-modal-title {
    color: var(--white-color);
  }
}

.uk-modal-page {
  overflow: visible !important; 
}

.uk-modal-page > body {
  overflow-y: visible !important;
  overflow-x: hidden !important; 
}

/* Navigation */
nav {
  height: 48px;

  i {
    padding-right: 8px;
    color: var(--white--color);
  }
}

.uk-navbar-container:not(.uk-navbar-transparent),
.uk-navbar {
  color: var(--white-color);
  background: var(--app-background-color-gradient);
}

.uk-navbar-item, .uk-navbar-nav>li>a, .uk-navbar-toggle {
  min-height: 48px;
  color: var(--white-color);
}

.uk-navbar-nav > li:hover > a {
  color: var(--app-secondary-color);
  padding-top: 1px;
}

.uk-navbar-dropdown {
  color: var(--text-color);
  background: var(--app-highlight-color); 

  .uk-nav-header {
    color: var(--text-color);
  }
}

.uk-nav-header {
  color: var(--text-color);
}

.uk-navbar-dropdown-nav .uk-nav-divider {
  border-top: 1px solid var(--border-color);
}

.uk-nav-default>li>a {
  color: var(--text-color);
}

/* Notifications */
.uk-notification {
  margin-bottom: 30px;
  width: 500px;
}

.uk-notification-message {
  font-size: 12px;
  color: var(--white-color);
  background: #232332ee;
  border-radius: 5px;
  word-break: break-word;
}

.uk-notification-message h1 {
  color: var(--white-color);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.uk-text-lead {
  color: var(--text-color);
}

/* Cards */
.uk-card-default {
  background: var(--app-clear-color);
  border: 1px solid var(--border-color);

  &.uk-card-hover:hover {
    background: var(--app-highlight-color-gradient);
  }

  .uk-card-title {
    color: var(--text-color);
    font-size: 1.25em;
  }

  .uk-card-footer {
    border-top: 1px solid var(--border-color); 
  }
}
.uk-card-secondary {
  background: var(--app-secondary-color);
  color: black;
  .uk-card-footer {
    border-top: 1px solid var(--border-color); 
  }

  .uk-card-body {
    color: var(--text-color);
  
    .uk-card-title {
      color: var(--app-primary-color);
    }
  }
}

.uk-card-secondary i,
.uk-card > a > i {
  color: var(--app-background-color);
}

.uk-card-body>:last-child,
.uk-card-footer>:last-child,
.uk-card-header>:last-child {
    margin-bottom: 0;
    margin-top: 0;
}

/* Tabs */
.uk-tab {
  a {
    font-size: 0.75em;
  }


  &::before {
    border-bottom: 1px solid var(--app-secondary-color);
  }

  .uk-active > a {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: var(--text-color);
  }
}

/* Labels */
.uk-label {
  font-size: 11px;
  border-radius: 2px;
}

.uk-label-success {
  background-color: var(--app-success-color);
}

.uk-label-warning {
  background-color: var(--app-warning-color);
}

.uk-label-danger {
  background-color: var(--app-danger-color);
}

/* Grid */
.uk-grid-divider>:not(.uk-first-column)::before {
  border-left: 1px solid var(--border-color);
}

/* Breadcrumb */
.uk-breadcrumb > * > * {
  text-transform: uppercase;
  font-size: 0.75em;
  color: var(--app-primary-color);
}

/* Forms */
.uk-form-label {
  color: var(--text-color); 
  font-size: 0.75em;
  text-transform: uppercase;
}
.uk-input,
.uk-select,
.uk-textarea {
  border: 1px solid var(--border-color);
  background: var(--app-background-color);
  color: var(--text-color);
}

.uk-search-default .uk-search-input {
  background: var(--app-background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

.uk-input:disabled,
.uk-select:disabled,
.uk-textarea:disabled {
  border: none; 
  background: var(--app-background-color);
  color: var(--text-color);
}

.uk-dark {
  i {
    color: var(--white-color);
  }
}

/* Lists */
.uk-list {
  font-size: 0.85em;
}

.uk-list-striped>:nth-of-type(odd) {
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  background: var(--app-highlight-color-gradient); 
}

.uk-tooltip {
  word-wrap: break-word;
}

//Tags
.tag-item {
  background-color: #efefef;
  color: #666;
  display: inline-flex;
  border-radius: 33px;
}

// Form styles
.uk-input:focus, .uk-textarea:focus {
  background: var(--app-background-color);
  color: var(--text-color);
}

.invalid-icon {
  color: #f0506e!important;;
}

.invalid-border {
  border: 2px solid #f0506e !important;
  border-radius: 5px;
}

input[type="text"], input[type="url"], input[type="email"], input[type="password"], input[type="search"], textarea {
  background-color: var(--app-input-bg)!important;
}

.inline-error-bg {
  /* background-color: var(--app-input-bg); */
  background-color: var(--app-input-bg);
  margin-top: 2px;
  height: 90%;
  white-space: pre;
  color: #f0506e!important;
}

// Date Picker
.field-input {
  color: var(--text-color)!important;
  border-color: var(--border-color)!important;
}
div > .custom-button.field-clear-button  {
  background-color: var(--app-input-bg)!important;
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

// Scrollbar
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;               /* width of the entire scrollbar */
}
::-webkit-scrollbar-track {
  background: var(--app-input-bg);        /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
  background-color: var(--muted-text-color);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  // border: 3px solid orange;  /* creates padding around scroll thumb */
}


// vue-good-table

table.vgt-table, .vgt-input {
  background: none;
  border: 1px solid var(--border-color);
}
.vgt-inner-wrap {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.vgt-global-search {
  background: none;
  border: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.vgt-global-search label {
  position: absolute;
  left: 10px;
}
.vgt-global-search__input .input__icon .magnifying-glass  {
  margin-left: 0px;
}
.vgt-global-search .vgt-input {
  width: 40%;
  margin-left: -40px;
  height: 40px;
  padding-left: 40px;
  color: var(--text-color);
}
.vgt-global-search .vgt-input::placeholder {
  color: var(--text-color);
}
table.vgt-table {
  font-size: 12px;
  border: 0px;
}
table.vgt-table td {
  border: 0px;
  color: var(--text-color);
}
.vgt-table thead th {
  background: none;
  color: var(--white-color);
  border: 0px;
  width: initial;
}
.vgt-table th.line-numbers {
  background: none;
  border-right: 1px solid var(--border-color);
}
.vgt-table th.sortable button:after {
  border-bottom: 5px solid var(--white-color);
}
.vgt-table th.sortable button:before {
  border-top: 5px solid var(--white-color);
}
.vgt-table .custom-center span {
  padding-left: 10px;
}
.vgt-wrap__footer {
  background: none;
  border: 0px;
}
.vgt-wrap__footer .footer__navigation__page-info__current-entry {
  background: none;
  border: 1px solid var(--border-color);
  color: #909399;
  padding: 2px;
}
.vgt-wrap__footer .footer__row-count__label {
  font-size: 13px;
}

// Spinner
.uk-spinner {
  color: var(--text-color)!important;
}

form > .uk-modal-body em,
.uk-modal-body p {
  max-width: 100%;
  word-break: break-all;
}