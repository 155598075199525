@import "@/styles/_variables.scss";




































































































































































































.no-hover {
  color: var(--app-primary-color)
}
.uk-navbar-transparent {
  background: none;
}
.hero-image {
  width: 100%;
}
.bg-linear-gradient {
  // background: linear-gradient(360deg, rgba(33, 30, 40, 0.63) 0.58%, rgba(0, 0, 0, 0) 48.16%)!important;
  background: rgba(0, 0, 0, 0.63);
}

.dark-background {
  background: #131319;
}


.neutral-card {
  border: 2px solid rgba(255, 255, 255, 0.06);
  /* Card/Large Shadow */
  box-shadow: 0px 14px 74px rgba(0, 0, 0, 0.45);
  border-radius: 22px;
  background: #1D1A27;
}

.front-headline {
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  color: #FFFFFF;
}

.front-card-header {
  font-size: 24px;
  color: #FFFFFF;
}
.front-card-body {
  font-size: 18px;
  line-height: 30px;
}

.front-navbar {
  background: rgba(0, 0, 0, 0.17);
}

.front-footer {
  background: #13111A;
}

.body-bg-pink {
  position: absolute;
  width: 578.87px;
  height: 578.87px;
  left: -155.4px;
  top: 941px;
  filter: blur(100px);

  /* Blob/Pink */

  // background: #FF4895;
  background: radial-gradient(#FF4895, transparent);
}

.body-bg-blue {
  /* Ellipse 412 */
  position: absolute;
  width: 561.35px;
  height: 561.35px;
  left: 216.77px;
  top: 1414.02px;
  filter: blur(100px);
  background: radial-gradient(#2D42FF 20%, transparent 100%);
}

.body-bg-blue-2 {
  /* Ellipse 410 */
  position: absolute;
  width: 578.87px;
  height: 578.87px;
  right: 200px;
  top: 1895.82px;
  filter: blur(100px);
  border-radius: 100000px;
  background: #2D42FF;
}

.body-bg-pink-2 {
  /* Ellipse 411 */
  position: absolute;
  width: 578.87px;
  height: 578.87px;
  right: 100.6px;
  top: 1490.04px;

  /* Blob/Pink */
  filter: blur(100px);
  border-radius: 1000000px;
  background: #FF4895;
}

.half-opacity {
  opacity: .5;
  z-index: -1;
}

.long-card {
  height: 300px;
}

